<template>
	<div>
		<div class="title">证监会：支持头部证券公司通过并购重组等方式做优做强 打造一流的投资银行</div>
    <div class="data">2023-11-03</div>

    <!-- 内容 -->
		<div class="text">
			11月3日电，证监会将支持头部证券公司通过业务创新、集团化经营、并购重组等方式做优做强，打造一流的
			投资银行，发挥服务实体经济主力军和维护金融稳定压舱石的重要作用；引导中小机构结合股东背景、区域优
			势等资源禀赋和专业能力做精做细，实现特色化、差异化发展；推动证券公司投行、投资、投研协同联动，不
			断提升服务实体经济、服务注册制改革能力，助力构建为实体企业提供多元化接力式金融服务体系。
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
			}
		},
		mounted() {
		    
		},
		methods: {
      
		},
    created() {

    },
	}
</script>

<style scoped>
.title{
  text-align: center;
  font-size: 26px;
  color: #4B4B4B;
	font-weight: bold;
  line-height: 180%;
	letter-spacing: 1px;
  margin: 20px 0 15px;
}
.data{
  color: #B3B3B3;
  text-align: center;
  margin: 15px 0 30px;
}
.text{
  line-height: 30px;
  color: #4B4B4B;
  font-size: 16px;
}
</style>
