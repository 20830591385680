<template>
	<div>
		<div class="title">证监会：加大长期资金对股权科创基金的投资力度</div>
    <div class="data">2023-10-26</div>
    <el-image :src="image" style="width: 100%;"></el-image>

    <!-- 内容 -->
    <div>
      <div class="smallTitle">关于政协十三届全国委员会第五次会议第2894号（财税金融类190号）提案答复的函</div>

      <div>《关于促进我国私募股权投资行业健康稳定发展的提案》收悉。经认真研究，现答复如下：</div>
      
      <div class="smallTitle">一、关于为私募股权市场引入更多源头活水</div>
      <div class="text">
        私募股权基金、创业投资基金（以下统称股权科创基金）对支持实体经济发展、提高直接融资比重和促进科技成果转化、
        科技创新意义重大。截至2022年6月底，在中国证券投资基金业协会（以下简称基金业协会）登记的私募股权、创业投资基
        金管理人1.48万家，管理基金资产规模13.42万亿元，占私募基金资产总规模67.2%。我国股权科创基金行业在快速发展同
        时，仍面临着制约其向高质量发展迈进的一些因素，其中，长期资金来源不足较为突出。为此，我会一直积极推动引入长期
        资金，改善行业募资环境：一是先后推动银保监会取消保险资金开展财务性股权投资的行业范围限制、取消保险资金投资创
        投基金“单只基金募集规模不超过5亿元”限制，为引入保险资金提供制度支持。二是配合发展改革委等出台《关于进一步明
        确规范金融机构资产管理产品投资创业投资基金和政府出资产业投资基金有关事项的通知》，放宽科创基金两层嵌套要求，
        为资管产品投资科创基金提供便利。三是与相关监管部门、行业协会、市场机构积极沟通推进社保基金、商业银行理财资金、
        保险资金等与股权科创基金合作，努力推动解决影响双方合作的难点痛点问题。下一步，我们将加大沟通力度，推动相关行
        业主管部门完善法律法规，加大以社保基金、保险资金、养老基金等为代表的长期资金对股权科创基金的投资力度。
      </div>
      
      <div class="smallTitle">二、关于加强对政府引导基金的顶层设计</div>
      <div class="text">
        政府引导基金具有中国特色，经过多年探索发展，在引导社会资金集聚、落实国家产业政策、优化资金配置方向、协调
        区域经济发展等方面发挥了积极作用。截至2021年末，在基金业协会备案的政府引导基金数量1728只，基金资产规模1.05
        万亿元。2021年新备案股权科创基金中，政府引导基金、国有基金等合计出资占比超过三成。政府引导基金尚在发展中，
        需要在平衡行政管理与市场竞争、资金安全与使用效率等问题上不断探索。下一步，我会将积极与相关主管部门加大沟通，
        推动政府引导基金在功能定位、考核机制、运作管理等方面进一步朝市场化、专业化方向迈进，不断完善顶层制度设计。
      </div>

      <div class="smallTitle">三、关于深化股权投资和创业投资份额转让试点工作</div>
      <div class="text">
        2020年7月国务院常务会决定，在区域性股权市场开展股权投资和创业投资份额转让试点。根据国务院常务会议决策部署等
        相关要求，我会于2020年12月、2021年11月先后复函同意北京、上海区域性股权市场启动份额转让试点。截至2022年7月
        底，北京股权交易中心完成21单份额转让项目，成交金额共计34亿元，15单份额质押登记项目，质押登记金额共计24亿元；
        上海股权托管交易中心完成8单份额转让项目，成交金额共计10亿元。区域性股权市场与私募基金协同发展取得初步成效。
        为解决国有基金份额转让、资产估值等实际问题，北京市和上海市相关单位均印发文件，支持国有基金份额通过区域性股权
        市场转让交易，上海市国资委印发了份额评估管理工作指引。目前，私募基金份额转让仍处于试点起步阶段，试点过程中的
        估值、定价、信息对称和中介机构专业能力等问题需要在市场发展中进一步探索，行政机关将积极进行引导并完善规则体系，
        推动试点工作顺利开展。感谢对资本市场发展的关心和支持，欢迎继续提出意见和建议。
      </div>

      <div class="footer" style="margin-top: 50px;">中国证监会</div>
      <div class="footer">2022年8月9日</div>
    </div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
        image: require('@/static/images/news/new1.png'),
			}
		},
		mounted() {
		    
		},
		methods: {
      
		},
    created() {

    },
	}
</script>

<style scoped>
.title{
  text-align: center;
  font-size: 26px;
  color: #4B4B4B;
	font-weight: bold;
  line-height: 180%;
	letter-spacing: 1px;
  margin: 20px 0 15px;
}
.data{
  color: #B3B3B3;
  text-align: center;
  margin: 15px 0 30px;
}
.smallTitle{
  margin: 30px 0;
  font-size: 18px;
  color: #4B4B4B;
	font-weight: bold;
}
.text{
  line-height: 30px;
  color: #4B4B4B;
  font-size: 16px;
}
.footer{
  text-align: right;
  color: #4B4B4B;
  line-height: 40px;
  font-size: 16px;
}
</style>
