<template>
	<div>
		<!-- 圣杯布局 -->
		<Header style="position: fixed;"></Header>
		<div style="height: 60px;"></div>
		<div class="banner"></div>

		<div class="container">
			<div class="left"></div>
			<div class="center">
				<div v-if="id == 1">
          <articleOne></articleOne>
        </div>
				<div v-if="id == 2">
          <articleTwo></articleTwo>
        </div>
				<div v-if="id == 3">
          <articleThree></articleThree>
        </div>
				<div v-if="id == 4">
          <articleFour></articleFour>
        </div>
			</div>
			<div class="right" ></div>
		</div>
		
		<Footer style="margin-top: 30px;"></Footer>
	</div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import articleOne from "@/views/news/articleOne";
import articleTwo from "@/views/news/articleTwo";
import articleThree from "@/views/news/articleThree";
import articleFour from "@/views/news/articleFour";
export default {
  data() {
    return {
      id: this.$route.query.id,
    }
  },
  components: {
    Header,
    Footer,
    articleOne,
    articleTwo,
    articleThree,
    articleFour,
  },
  mounted() {
      
  },
  methods: {
    
  },
  created() {
    console.log(this.$route.query.id)
  },
}
</script>

<style scoped>
.container{
	margin: 60px 0;
    display: flex;
    flex-direction: row;
    flex: 1;
}
.center{
    flex: 0 0 1200px;
}
.left{
    flex: 1;
}
.right{
    flex: 1;
}

.banner {	height: 55vh;	display: flex;	align-items: center;	justify-content: center;	background: url("../static/images/news/002.jpg") no-repeat center center;	background-size: cover; }
</style>
